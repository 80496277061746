import React from 'react'
import PropTypes from 'prop-types'
import styles from './masonry.module.css'

const Masonry = ({ images }) => (
  <div className={styles.root}>
    <div className={styles.grid}>
      {images.map(({ src, alt }, i) => (
        <img loading='lazy' key={src + i} className={styles.image} src={src} alt={alt} />
      ))}
    </div>
  </div>
)

Masonry.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
}

export default Masonry
